/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import * as React from "react";
import type { GatsbyBrowser } from "gatsby";

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  console.log("We've started!");
}
