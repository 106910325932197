module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":660,"showCaptions":["title"],"markdownCaptions":true,"withWebp":true,"linkImagesToOriginal":false,"backgroundColor":"transparent","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Math Geek","short_name":"Math Geek","start_url":"/","background_color":"#eff5fb","theme_color":"#4499d2","display":"minimal-ui","icon":"./content/images/math_geek_icon_015.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1baf0dfdce576ef5f4e29da97ee4eda9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-portfolio-minimal/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.themathgeek.pro","manifestSettings":{"favicon":"./content/images/math_geek_icon_015.png","siteName":"The Math Geek","shortName":"Math Geek","startUrl":"/","backgroundColor":"#eff5fb","themeColor":"#4499d2","display":"minimal-ui"},"contentDirectory":"./content","blogSettings":{"entityName":"Article","path":"/blog","usePathPrefixForArticles":false}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
